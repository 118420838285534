import React, { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { setSocketId } from "../utils/constant";
import { fetchLudoPlayerStatus } from "../services/backendServices";
import LudoOption from "../components/ludo/ludo-option";

function Ludo() {
	const [screen, setScreen] = useState(true);

	const fetchData = async () => {
		try {
			const res = await fetchLudoPlayerStatus();
			if (res.status) {
				setSocketId(res.ludo_session_id);
			}
			//temp update
			if (res.status && res.ludo_session_id) {
				window.location.href = "https://betaplay.winyar.com/ludoplay/";
			} else {
				setScreen(false);
			}
		} catch (error) {
			console.log("error in ludo contest api : ", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			{screen ? (
				<div
					className="relative w-screen h-screen overflow-hidden text-left text-white bg-dark-blue font-fs-elliot-pro flex flex-col justify-center items-center"
					style={{ fontSize: "45px" }}
				>
					<i className="font-extrabold mb-3">LUDO </i>

					<ThreeCircles
						visible={true}
						height="100"
						width="100"
						glassColor="#c0efff"
						color="#e15b64"
						ariaLabel="three-circles-loading"
						wrapperStyle={{}}
						wrapperClass=""
					/>
				</div>
			) : (
				<LudoOption />
			)}
		</>
	);
}

export default Ludo;
