import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../shared/layout";
import { notifyError, notifySuccess } from "../utils/constant";
import { getPaymantType, updatePaymentType } from "../services/backendServices";

function Recharge() {
	const [selectPaymentType, setSelectPaymentType] = useState("unset");
	const handleOptionChange = (event) => {
		setSelectPaymentType(event.target.value);
	};

	const [paymentType, setPaymentType] = useState(null);

	const fetchData = () => {
		getPaymantType()
			.then((res) => {
				if (!res.status) {
					notifyError(res.msg);
				} else {
					setPaymentType(res.user_payment_type);
				}
			})
			.catch((error) => {
				console.error("Error fetching user payment type history:", error);
			});
	};

	const navigate = useNavigate();
	useEffect(() => {
		fetchData();
	}, []);

	const handleConfirm = () => {
		if (selectPaymentType !== "unset") {
			updatePaymentType(selectPaymentType)
				.then((res) => {
					if (!res.status) {
						notifyError(res.msg);
					} else {
						notifySuccess(res.msg);
						fetchData();
					}
				})
				.catch((error) => {
					console.error("Error updating payment type:", error);
				});
		}
	};

	// Redirect based on payment type
	useEffect(() => {
		console.log("paymentType", paymentType);
		if (paymentType === "upi") {
			navigate("/upi-payment");
		} else if (paymentType === "crypto") {
			navigate("/crypto-payment");
		}
	}, [paymentType, navigate]);

	return (
		<>
			<Layout>
				{paymentType === "unset" ? (
					<>
						<div className="flex justify-center items-center text-4xl mt-5 mb-10">
							Payment Method
						</div>
						<div className="flex justify-center items-center text-4xl mt-5 mb-5">
							<div
								className="flex justify-center items-center"
								onClick={() => setSelectPaymentType("upi")}
							>
								<img className="w-[60%]" alt="" src="./assets/upi.svg" />
							</div>
							<b>OR</b>
							<div
								className="flex justify-center items-center"
								onClick={() => setSelectPaymentType("crypto")}
							>
								<img className="w-[35%]" alt="" src="./assets/metamask.svg" />
							</div>
						</div>
						<div className="flex justify-center my-5">
							<select
								className="rounded-8xs bg-gray w-[90%] mt-2 h-[35px] pl-2 flex flex-row box-border text-[18px] text-sm"
								value={selectPaymentType}
								onChange={handleOptionChange}
							>
								<option className="text-sm w-[90%]" value="unset">
									Select Payment Type
								</option>
								<option className="text-sm w-[90%]" value="upi">
									INR- Rupees
								</option>
								<option className="text-sm w-[90%]" value="crypto">
									Crypto
								</option>
							</select>
						</div>
						<div className="justify-center flex ">
							<button
								className="w-[90%] rounded-md bg-orange-100 text-dark-blue px-3 py-3.5 text-sm font-semibold text-5xl text-dark-blue shadow-sm hover:bg-orange-200"
								onClick={handleConfirm}
							>
								confirm
							</button>
						</div>
						{/* <p className="mt-2 text-center text-white text-xs">
							*After confirm, you can't change your payment type
						</p> */}
					</>
				) : null}
			</Layout>
		</>
	);
}

export default Recharge;
