import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../utils/constant";

function PrivateRoute({ component: Component }) {
	const loggedInUser = getToken();
	// const [verify, setVerify] = useState(false);
	// const navigate = useNavigate();

	// if (loggedInUser) {
	//   const IP = API_URL;
	//   const requestOptions = {
	//     method: "POST",
	//     headers: { "Content-Type": "application/json" },
	//     body: JSON.stringify({ loggedInUser }),
	//   };
	//   fetch(IP + "auth/verify", requestOptions)
	//     .then((response) => response.json())
	//     .then((data) => {
	//       if (data.code === "token_not_valid") {
	//         navigate("/login");
	//       }
	//     })
	//     .catch((error) => {
	//       console.error("Error:", error);
	//       // navigate("/login");
	//     });
	// }

	return loggedInUser ? (
		<Suspense
			fallback={
				<div
					className="relative w-screen h-screen overflow-hidden text-left text-white bg-dark-blue font-fs-elliot-pro flex justify-center items-center"
					style={{ fontSize: "30px" }}
				>
					<div className="flex flex-row items-center justify-between h-[60px] px-4 md:px-12 gap-[200px]">
						<i className="relative font-extrabold whitespace-nowrap">
							<span>WIN-</span>
							<span className="text-blue-main">YAR</span>
						</i>
					</div>
				</div>
			}
		>
			<Component />
		</Suspense>
	) : (
		<Navigate to="/" />
	);
}

export default PrivateRoute;
