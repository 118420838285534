import { useEffect, useState } from "react";
import { ludoSocketInstance, reConnectSocket } from "./ludo-socket";
import { getSocketId } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const LudoPlay = () => {
	// ludo result moda
	const [waiting, setWaiting] = useState(false);

	const session_id = getSocketId();
	const navigate = useNavigate();

	let isConnected;

	useEffect(() => {
		isConnected = ludoSocketInstance?.connected;

		if (isConnected) {
			ludoSocketInstance?.on("resWaitingToRollDice", (data) => {
				setWaiting(false);
			});

			ludoSocketInstance?.on("resGameStarted", () => {
				setWaiting(false);
			});

			ludoSocketInstance?.on("resDiceRolled", (data) => {
				setWaiting(false);
			});
		} else {
			reConnectSocket(session_id);
			ludoSocketInstance?.on("resWaitingToRollDice", (data) => {
				setWaiting(false);
			});

			ludoSocketInstance?.on("resGameStarted", () => {
				setWaiting(false);
			});

			ludoSocketInstance?.on("resDiceRolled", (data) => {
				setWaiting(false);
			});
		}
	}, [isConnected, ludoSocketInstance]);

	useEffect(() => {
		if (!waiting) {
			window.location.href = "https://betaplay.winyar.com/ludoplay/";
			return () => {
				ludoSocketInstance?.disconnect();
			};
		}
	}, [waiting]);

	return (
		<>
			{waiting && (
				<div className="flex justify-center items-center h-screen w-screen">
					<div
						className="w-full h-full bg-no-repeat bg-cover"
						style={{ backgroundImage: "url('./assets/ludo/home_bg.svg')" }}
					>
						<div
							className="flex justify-center items-center mt-10"
							style={{ height: "30vh" }}
						>
							<img src="./assets/ludo/ludotext.png" />
						</div>
						<div
							className="flex justify-center items-center mt-10"
							style={{ height: "25vh", width: "auto" }}
						>
							<img src="./assets/ludo/ludo.png" />
						</div>

						<div
							className="flex flex-col justify-center items-center mt-10"
							style={{ height: "25vh" }}
						>
							<img src="./assets/ludo/loading.png" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LudoPlay;
