import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JoinRoomSocket, ludoSocketInstance } from "./ludo-socket";
import cogoToast from "cogo-toast";
import RechargeModal from "../../shared/recharge-modal";
import { MagnifyingGlass, ThreeCircles } from "react-loader-spinner";
import { setSocketId } from "../../utils/constant";
import { fetchLudoPlayerStatus } from "../../services/backendServices";

function AutoJoin() {
	const { roomcode } = useParams();
	const [rechargeModal, setRechargeModal] = useState(false);

	const navigate = useNavigate();

	const handleJoin = () => {
		if (roomcode) {
			JoinRoomSocket(roomcode);
		} else {
			cogoToast.warn(`Please Enter Valid Code`);
		}

		ludoSocketInstance?.on("resPlayerIndex", (data) => {
			// console.log("Join resPlayerIndex");
		});

		ludoSocketInstance?.on("resInsufficientBalance", () => {
			// console.log("resInsufficientBalance");
			setRechargeModal(true);
		});
		ludoSocketInstance?.on("resJoinFailed", (res) => {
			cogoToast.warn(`${res.msg}`);
			navigate("/ludo");
		});
		ludoSocketInstance?.on("resGameLoading", () => {
			// console.log("resGameLoading navigate");
			navigate("/ludo-play");
		});

		ludoSocketInstance?.on("resWaitingForPlayers", () => {
			// console.log("resWaitingForPlayers navigate");
		});
	};
	const [screen, setScreen] = useState(true);

	const fetchData = async () => {
		try {
			const res = await fetchLudoPlayerStatus();
			if (res.status) {
				setSocketId(res.ludo_session_id);
			}
			//temp update
			if (res.status && res.ludo_session_id) {
				window.location.href = "https://betaplay.winyar.com/ludoplay/";
			} else {
				setScreen(false);
			}
		} catch (error) {
			setScreen(false);
			console.log("error in ludo contest api : ", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (!screen) {
			handleJoin();
		}
	}, [screen]);

	return (
		<>
			{screen ? (
				<div
					className="relative w-screen h-screen overflow-hidden text-left text-white bg-dark-blue font-fs-elliot-pro flex flex-col justify-center items-center"
					style={{ fontSize: "45px" }}
				>
					<i className="font-extrabold mb-3">LUDO </i>

					<ThreeCircles
						visible={true}
						height="100"
						width="100"
						glassColor="#c0efff"
						color="#e15b64"
						ariaLabel="three-circles-loading"
						wrapperStyle={{}}
						wrapperClass=""
					/>
				</div>
			) : (
				<div>
					<div className="flex justify-center items-center h-screen w-screen">
						<div
							className="w-full h-full bg-no-repeat bg-cover"
							style={{ backgroundImage: "url('/assets/ludo/home_bg.svg')" }}
						>
							<div
								className="flex justify-center items-center mt-10"
								style={{ height: "30vh" }}
							>
								<img src="/assets/ludo/ludotext.png" />
							</div>
							<div
								className="flex justify-center items-center mt-10"
								style={{ height: "20vh", width: "auto" }}
							>
								<img src="/assets/ludo/ludo.png" />
							</div>

							<div
								className="flex flex-col justify-center items-center mt-10"
								style={{ height: "25vh" }}
							>
								<MagnifyingGlass
									visible={true}
									height="80"
									width="80"
									ariaLabel="magnifying-glass-loading"
									wrapperStyle={{}}
									wrapperClass="magnifying-glass-wrapper"
									glassColor="#c0efff"
									color="#e15b64"
								/>
								<img src="/assets/ludo//wait-text.png" width={320} />
							</div>
						</div>
					</div>
					<RechargeModal
						rechargeModal={rechargeModal}
						setRechargeModal={setRechargeModal}
					/>
				</div>
			)}
		</>
	);
}

export default AutoJoin;
