import GamesSection from "../components/home/games-section";
import WalletCard from "../components/home/wallet-card";
import Layout from "../shared/layout";

const popularGames = [
	{
		name: "",
		link: "/ludo",
		image: "./assets/games/ludo.png",
	},
	{
		name: "",
		link: "/aviator",
		image: "./assets/games/rocket_new.png",
	},
	{
		name: "",
		link: "/hilo",
		image: "./assets/games/hilo_new.png",
	},
	{
		name: "",
		link: "/andar-bahar",
		image: "./assets/games/andarbahar_new.png",
	},
	{
		name: "",
		link: "/parity",
		image: "./assets/games/parity_new.png",
	},

	{
		name: "",
		link: "/dashboard",
		image: "./assets/games/rollet_new.png",
	},
];

const newGames = [...popularGames];

const Home = () => {
	return (
		<Layout>
			<>
				<section className="flex flex-col justify-start gap-4 md:flex-row">
					<div className="flex flex-col">
						<WalletCard />
					</div>
				</section>
				<GamesSection title="New" games={newGames} />
			</>
		</Layout>
	);
};

export default Home;
