import React, { useEffect, useState } from "react";
import Layout from "../../shared/layout";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import SubHeader from "../../shared/sub-header";
import {
	API_URL,
	getToken,
	notifyError,
	notifySuccess,
	removeLocalStorage,
} from "../../utils/constant";
import { getPaymantType } from "../../services/backendServices";

const Payment = () => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null); // New state for image preview
	const navigate = useNavigate();
	const [lodding, setLoadding] = useState(false);

	const handleImageUpload = (event) => {
		const file = event.target.files[0];

		// Check if the file is of allowed type (jpg or png)
		if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
			setSelectedImage(file);

			const previewURL = URL.createObjectURL(file);
			setImagePreview(previewURL);
		} else {
			// Display a toast notification for invalid file type
			notifyError("Only JPG and PNG images are allowed.");
		}
	};

	const handleImageRemove = () => {
		setSelectedImage(null);
		setImagePreview(null);
	};
	// const handleImageUpload = (event) => {
	//   const file = event.target.files[0];
	//   setSelectedImage(file);

	//   // Create a preview URL for the selected image
	//   const previewURL = URL.createObjectURL(file);
	//   setImagePreview(previewURL);
	// };

	const notifyCopyImg = (msg) => {
		notifyError(msg);
	};

	const [paymentType, setPaymentType] = useState();

	useEffect(() => {
		getPaymantType()
			.then((res) => {
				if (!res.status) {
					notifyError(res.msg);
				} else {
					setPaymentType(res.user_payment_type);
				}
			})
			.catch((error) => {
				console.error("Error fetching user payment type history:", error);
			});
	}, []);

	const handleImageSubmit = async () => {
		// console.log("===", paymentType);
		setLoadding(true);
		const token = getToken();
		if (token && selectedImage) {
			const IP = API_URL;
			const decoded = jwt_decode(token);
			const userId = decoded.userId;

			let apiUrl;
			if (paymentType === "upi") {
				apiUrl = "/payment/addScreenshot";
			} else {
				apiUrl = "/payment/addCryptoScreenshot";
			}

			const formData = new FormData();
			formData.append("user_id", userId);
			formData.append("screenshot", selectedImage);
			formData.append("payment_type", paymentType);
			formData.append("deposit_request_amount", location.state.amount);
			try {
				const response = await fetch(IP + apiUrl, {
					method: "POST",
					body: formData,
					headers: {
						Authorization: token,
					},
				});

				const data = await response.json(); // Parse the JSON response
				// console.log(response.status);
				if (response.ok && data.status !== false) {
					// console.log("Status:", data.status);
					notifySuccess(data.msg);
					navigate("/dashboard");
				} else {
					if (response.status === 401) {
						notifyError(data.msg);
						removeLocalStorage();
						setTimeout(() => {
							navigate("/login");
						}, 500);
					} else {
						// console.log("Message:", res.msg);
						notifyCopyImg(data.msg);
						// console.error("Image upload failed");
					}
				}
			} catch (error) {
				console.error("Error payment occurred:", error);
			} finally {
				setLoadding(false);
			}
		}
	};

	const location = useLocation();

	return (
		<Layout>
			<SubHeader subTitle="Payment" showRule={false} backLink="/recharge" />
			<div className="relative p-2 mt-6 text-base rounded-lg text-lightslategray font-fs-elliot-pro bg-darkslategray p-[10px]">
				<div className="w-full bg-gray-100 text-sm mt-5">
					<div className="flex items-center py-4 px-6">
						<div className="flex-grow font-semibold text-lg text-white">
							Confirm payment
						</div>
					</div>
					<div className="px-6 pb-4">
						<div className="text-white">
							If you have paid, please upload a screenshot
						</div>
						<div className={`py-4 mt-3 flex justify-center`}>
							{imagePreview ? (
								<div className="relative">
									<img
										src={imagePreview}
										alt="Selected"
										className="max-w-[160px] h-auto mb-2"
									/>
									<button
										className="absolute top-[-6px] right-[-6px] flex justify-center items-center p-2 bg-orange-100 text-dark-blue rounded-full hover:bg-red-600"
										onClick={handleImageRemove}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											className="w-4 h-4"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</button>
								</div>
							) : (
								<label
									htmlFor="ssupload"
									className="dropzone dz-clickable zooani w-[150px] h-[150px] border-dashed border-2 border-gray-300 rounded-lg flex flex-col items-center justify-center"
								>
									<input
										type="file"
										accept="image/*"
										onChange={handleImageUpload}
										className="sr-only"
										id="ssupload"
									/>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										className="w-8 h-8 text-gray-400"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M12 6v6m0 0v6m0-6h6m-6 0H6"
										/>
									</svg>
									<span className="text-gray-400 text-sm mt-2 px-5">
										Click or drag and drop image here
									</span>
								</label>
							)}
						</div>
						{selectedImage && (
							<div className="flex justify-center">
								<button
									className="mt-4 bg-orange-100 text-dark-blue px-4 py-2 rounded"
									onClick={!lodding ? handleImageSubmit : undefined}
								>
									{lodding ? "Uploading" : "Submit Image"}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Payment;
