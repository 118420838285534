import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SubHeader = ({ subTitle, showRule, backLink = "/dashboard" }) => {
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const [title, setTitle] = useState("");
	const path = useLocation();
	const handleRules = () => {
		if (path.pathname.includes("andar-bahar")) {
			setTitle("Join Andar-Bahar");
			let content = (
				<div className="text-sm text-justify">
					Andar Bahar is a{" "}
					<b>guessing game where you can choose Andar, Bahar, or Tie.</b>
					<br />
					<br />
					You have 30 seconds to place your order. After the order time, a hole
					card is shown first.Then, the cards are dealt in the order of Andar →
					Bahar. The card with the same number of points as the hole card is
					dealt first, determining the winning side.
					<br />
					<br />
					If the hole card shows a 2, the side that shows the 2 first (Andar or
					Bahar) wins.If no 2 is shown in 30 consecutive cards, it's a tie.{" "}
					<b>If you spend ₹100 to trade, your contract amount is 100 rupees:</b>
					<br />
					<ul className="p-[10px]">
						<li className="mb-2">
							Buy Andar: You will get (100 * 2 * 0.05){" "}
							<span className="text-wingreen">
								<b> 190 rupees.</b>
							</span>
						</li>
						<li className="mb-2">
							Buy Bahar: You will get (100 * 2 * 0.05 service fee){" "}
							<span className="text-wingreen">
								<b>190 rupees.</b>
							</span>
						</li>
						<li>
							Buy a Tie: If neither Andar nor Bahar shows 2, you will get (100 *
							9 * 0.05 service fee){" "}
							<span className="text-wingreen">
								<b> 855 rupees.</b>
							</span>
						</li>
					</ul>
				</div>
			);
			setModalContent(content);
			setShowModal(true);
		} else if (path.pathname.includes("hilo")) {
			setTitle("Join Hi-Lo");
			let content = (
				<div className="text-sm text-justify">
					Hilo is a{" "}
					<b>guessing game where you can choose next card is HIGH or LOW</b>
					<br />
					<br />
					After starting the game, a hole card will be shown, and the next card
					will be higher or lower than the current card. If you guess correctly,
					you can get the corresponding reward. Then you can continue to flop,
					or you can end the game to get a reward.
					<br />
					<br />
					<ul className="p-[10px]">
						<li className="mb-2">
							When the hole card is K, choose to be equal to or lower than K;
						</li>
						<li className="mb-2">
							When the hole card is A, choose to be equal to A or higher than A
						</li>
						<li>
							When the hole card is not K or A, choose higher than or equal to
							or lower than or equal to.
						</li>
					</ul>
				</div>
			);
			setModalContent(content);
			setShowModal(true);
		} else if (path.pathname.includes("rocket")) {
			setTitle("Join rocket");
			let content = (
				<div className="text-sm text-justify">
					Rocket is a{" "}
					<b>
						game that multiply your Money with the X multiplier, it can be 1X,
						5X, 10X etc.
					</b>
					<br />
					<br />
					<ul className="p-[10px] m-0">
						<li className="mb-2">
							First, choose how much you want to place order. Click{" "}
							<b>“START”</b> to place your order. watch the multiplier rise from
							X upwards!
						</li>
						<li className="mb-2">
							You can click <b>“STOP”</b> at any time in order to multiply your
							Money with the current X multiplier.
						</li>
					</ul>
					The game can end at any time, and if you haven’t stop by then, you get
					nothing!
				</div>
			);
			setModalContent(content);
			setShowModal(true);
		} else if (path.pathname.includes("parity")) {
			setTitle("Join Parity");
			let content = (
				<div className="text-sm text-justify">
					<b>
						30 seconds 1 issue,24 seconds to order,6 seconds to show the lottery
						result. It opens all day. The total number of trade is 2880 issues.
					</b>
					<br />
					<br />
					If you spend 100 rupees to trade,after deducting 2 rupees service
					fee,your contract amount is 98 rupees:
					<ul className="p-[10px] m-0">
						<li className="mb-2">
							JOIN GREEN:if the result shows 1,3,7,9,you will get (98*2) 196
							rupees;If the result shows 5,you will get (98*1.5) 147 rupees.
						</li>
						<li className="mb-2">
							JOIN RED:if the result shows 2,4,6,8,you will get (98*2) 196
							rupees;If the result shows 0,you will get (98*1.5) 147 rupees.
						</li>
						<li className="mb-2">
							JOIN VIOLET:if the result shows 0 or 5,you will get (98*4.5) 441
							rupees.
						</li>
					</ul>
					<div class="flex justify-center">
						<table class="table table-bordered custom-table">
							<thead>
								<tr>
									<th>Select</th>
									<th>Result</th>
									<th>Multiplier</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center" rowspan="2">
										<span className="text-lightgreen">Join Green</span>
									</td>
									<td>1,3,7,9</td>
									<td>2</td>
								</tr>
								<tr>
									<td>5</td>
									<td>1.5</td>
								</tr>
								<tr>
									<td class="text-center" rowspan="2">
										<span className="text-tomato">Join Red</span>
									</td>
									<td>2,4,6,8</td>
									<td>2</td>
								</tr>
								<tr>
									<td>0</td>
									<td>1.5</td>
								</tr>
								<tr>
									<td class="text-center">
										<span className="text-violet-400">Join Violet</span>
									</td>
									<td>0,5</td>
									<td>4.5</td>
								</tr>
								<tr>
									<td>Number</td>
									<td>n</td>
									<td>9</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			);
			setModalContent(content);
			setShowModal(true);
		}
	};

	return (
		<>
			<div className="sub-header bg-dark-blue h-[45px] flex flex-row items-center justify-between gap-[78px] text-xl text-lightslategray z-[1000]">
				<Link to={backLink}>
					<div className="h-[45px] overflow-hidden flex flex-row p-2 box-border items-center justify-center">
						<div>
							<svg
								width="11"
								height="19"
								viewBox="0 0 11 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.51844 17.8869L0.75347 10.145C0.661304 10.0528 0.595867 9.95294 0.557157 9.84542C0.518447 9.73789 0.4994 9.62268 0.500014 9.4998C0.500014 9.37691 0.519062 9.2617 0.557157 9.15417C0.595252 9.04665 0.66069 8.9468 0.75347 8.85464L8.51844 1.08967C8.73349 0.874616 9.00231 0.76709 9.32489 0.76709C9.64747 0.76709 9.92396 0.882297 10.1544 1.11271C10.3848 1.34313 10.5 1.61194 10.5 1.91916C10.5 2.22638 10.3848 2.4952 10.1544 2.72561L3.38019 9.4998L10.1544 16.274C10.3694 16.489 10.477 16.7542 10.477 17.0694C10.477 17.3846 10.3618 17.6571 10.1313 17.8869C9.90092 18.1173 9.63211 18.2325 9.32489 18.2325C9.01767 18.2325 8.74885 18.1173 8.51844 17.8869Z"
									fill="#858CAB"
								/>
							</svg>
						</div>
					</div>
				</Link>
				<b className="relative text-base whitespace-nowrap">{subTitle}</b>
				<div
					className="h-[45px] flex flex-row p-2 box-border items-center justify-center text-xs"
					onClick={handleRules}
				>
					{showRule && (
						<div className="relative" style={{ fontSize: "20px" }}>
							ⓘ&nbsp; &nbsp;
						</div>
					)}
				</div>
			</div>
			<RuleModel
				content={modalContent}
				showModal={showModal}
				setShowModal={setShowModal}
				title={title}
			/>
		</>
	);
};

export default SubHeader;

const RuleModel = ({ showModal, setShowModal, content, title }) => {
	return (
		<>
			{showModal ? (
				<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto ">
					<div
						className={`${
							title === "Join Rocket" ? "max-w-[80%]" : "max-w-[90%]"
						} relative md:max-w-3xl md:mx-auto md:my-6`}
					>
						<div className="relative p-3 border-0 rounded-lg shadow-lg outline-none md:w-full rounded-t-xl bg-darkslategray focus:outline-none">
							<div className="flex items-start justify-between pb-2 px-3 border-b border-gray-300 border-solid rounded-t">
								<h3 className="text-xl m-0 font-semibold text-white text-center">
									<b className="">{title}</b>
								</h3>
								<button
									className="float-right text-black bg-transparent border-0"
									onClick={() => setShowModal(false)}
								>
									<span className="block w-6 h-6 py-0 text-xl text-white bg-gray-400 rounded-full opacity-7">
										x
									</span>
								</button>
							</div>

							<div className="text-lg">
								<div className="box-border w-full h-px border-t-[1px] border-solid border-dark-blue" />
								<div className="max-h-[300px] overflow-y-auto p-3">
									{content}
								</div>
								<div
									className="m-2 rounded-3xs bg-wingreen h-[38px] flex flex-row py-3.5 px-5 box-border items-center justify-center text-center text-base"
									onClick={() => setShowModal(false)}
								>
									<b className="relative leading-[130.4%]">Play Now</b>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};
