import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkUserBlockStatus } from "../utils/constant";
import UserBlockModel from "./user-block-model";

function detectMobile() {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i,
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}
const Menubar = () => {
	const isMobile = detectMobile();
	const navigate = useNavigate();
	const path = useLocation();
	const [isBlock, setIsBlock] = useState(false);
	const isBlocked = checkUserBlockStatus();
	// console.log("====", isBlocked);

	if (isMobile) {
		return (
			<>
				<div className="fixed bottom-[25px] z-40  left-0 h-[74px] text-3xs text-lightslategray">
					<div className="fixed bottom-[25px] z-40 left-4 h-[74px] w-[calc(100vw_-_32px)] rounded-2xl bg-darkslategray " />
					<div className="fixed bottom-[40px] z-50 left-4 flex flex-row items-center justify-around w-[calc(100vw_-_32px)]">
						<div
							className={`h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
								path.pathname.includes("dashboard") ? "text-orange-100" : ""
							}`}
							onClick={() => navigate("/dashboard")}
						>
							<img
								className="relative w-[22px] h-[22px]"
								alt=""
								src={
									path.pathname.includes("dashboard")
										? "./assets/menu/home-icon-select.svg"
										: "./assets/menu/home-icon.svg"
								}
							/>
							<div className="relative">Home</div>
						</div>
						<div
							className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px]  ${
								path.pathname.includes("invite") ? "text-orange-100" : ""
							}`}
							onClick={() => navigate("/invite")}
						>
							<img
								className="relative w-[21.71px] h-[21.71px]"
								alt=""
								src={
									path.pathname.includes("invite")
										? "./assets/menu/invite-icon-select.svg"
										: "./assets/menu/invite-icon.svg"
								}
							/>
							<div className="relative">Invite</div>
						</div>
						<div
							className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
								path.pathname.includes("recharge") ||
								path.pathname.includes("add-money") ||
								path.pathname.includes("crypto-payment") ||
								path.pathname.includes("upi-payment") ||
								path.pathname === "/payment"
									? "text-orange-100"
									: ""
							}`}
							onClick={() =>
								isBlocked ? setIsBlock(true) : navigate("/recharge")
							}
						>
							<img
								className="relative w-6 h-[19.53px]"
								alt=""
								src={
									path.pathname.includes("recharge") ||
									path.pathname.includes("add-money") ||
									path.pathname.includes("crypto-payment") ||
									path.pathname.includes("upi-payment") ||
									path.pathname === "payment"
										? "./assets/menu/wallet-icon-select.svg"
										: "./assets/menu/wallet-icon.svg"
								}
							/>
							<div className="relative">Recharge</div>
						</div>
						<div
							className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
								path.pathname.includes("my") ||
								path.pathname.includes("records")
									? "text-orange-100"
									: ""
							}`}
							onClick={() => navigate("/my")}
						>
							<img
								className="relative w-[18px] h-[22px]"
								alt=""
								src={
									path.pathname.includes("my") ||
									path.pathname.includes("records")
										? "./assets/menu/person-icon-select.svg"
										: "./assets/menu/person-icon.svg"
								}
							/>
							<div className="relative">My</div>
						</div>
					</div>
				</div>
				<UserBlockModel isBlock={isBlock} setIsBlock={setIsBlock} />
			</>
		);
	}

	return (
		<>
			<div className="w-[74px] h-[calc(100vh_-_60px)] text-3xs bg-darkslategray  text-lightslategray">
				<div className="flex flex-col items-center py-4 justify-center gap-[45px]">
					<div
						className={`h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
							path.pathname.includes("dashboard") ? "text-orange-100" : ""
						}`}
						onClick={() => navigate("/dashboard")}
					>
						<img
							className="relative w-[22px] h-[22px]"
							alt=""
							src={
								path.pathname.includes("dashboard")
									? "./assets/menu/home-icon-select.svg"
									: "./assets/menu/home-icon.svg"
							}
						/>
						<div className="relative">Home</div>
					</div>

					<div
						className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
							path.pathname.includes("invite") ? "text-orange-100" : ""
						}`}
						onClick={() => navigate("/invite")}
					>
						<img
							className="relative w-[21.71px] h-[21.71px]"
							alt=""
							src={
								path.pathname.includes("invite")
									? "./assets/menu/invite-icon-select.svg"
									: "./assets/menu/invite-icon.svg"
							}
						/>
						<div className="relative">Invite</div>
					</div>

					<div
						className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
							path.pathname.includes("recharge") ||
							path.pathname.includes("add-money") ||
							path.pathname.includes("crypto-payment") ||
							path.pathname.includes("upi-payment") ||
							path.pathname.includes("payment")
								? "text-orange-100"
								: ""
						}`}
						onClick={() =>
							isBlocked ? setIsBlock(true) : navigate("/recharge")
						}
					>
						<img
							className="relative w-6 h-[19.53px]"
							alt=""
							src={
								path.pathname.includes("recharge") ||
								path.pathname.includes("add-money") ||
								path.pathname.includes("crypto-payment") ||
								path.pathname.includes("upi-payment") ||
								path.pathname.includes("payment")
									? "./assets/menu/wallet-icon-select.svg"
									: "./assets/menu/wallet-icon.svg"
							}
						/>
						<div className="relative">Recharge</div>
					</div>
					<div
						className={`w-11 h-11 flex flex-col p-2 box-border items-center justify-center gap-[10px] ${
							path.pathname.includes("my") || path.pathname.includes("records")
								? "text-orange-100"
								: ""
						}`}
						onClick={() => navigate("/my")}
					>
						<img
							className="relative w-[18px] h-[22px]"
							alt=""
							src={
								path.pathname.includes("my") ||
								path.pathname.includes("records")
									? "./assets/menu/person-icon-select.svg"
									: "./assets/menu/person-icon.svg"
							}
						/>
						<div className="relative">My</div>
					</div>
				</div>
			</div>
			<UserBlockModel isBlock={isBlock} setIsBlock={setIsBlock} />
		</>
	);
};

export default Menubar;
